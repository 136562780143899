import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DialogFormWrapper',{scopedSlots:_vm._u([{key:"form",fn:function(){return [_c(VForm,{ref:"addNewTaskForm",staticClass:"w-100 pt-6",attrs:{"id":"addNewTaskForm"},on:{"submit":function($event){$event.preventDefault();return _vm.submitNewTaskForm.apply(null, arguments)}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"outlined":"","label":"Zlecający pracownik","readonly":"","value":_vm.fullName}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VAutocomplete,{attrs:{"outlined":"","items":_vm.users,"label":"Przypisany pracownik","placeholder":"Przypisz pracownika","item-text":function (item) { return ((item.firstName) + " " + (item.lastName)); },"item-value":"id","rules":[_vm.rules.required],"append-icon":"mdi-chevron-down","dense":""},model:{value:(_vm.task.assignedUserId),callback:function ($$v) {_vm.$set(_vm.task, "assignedUserId", $$v)},expression:"task.assignedUserId"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"outlined":"","label":"Temat","rules":[_vm.rules.required],"placeholder":"Wpisz temat"},model:{value:(_vm.task.topic),callback:function ($$v) {_vm.$set(_vm.task, "topic", $$v)},expression:"task.topic"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VMenu,_vm._b({scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"value":_vm.formattedDate,"rules":_vm.showDatePicker ? [] : [_vm.rules.required],"label":"Data realizacji","placeholder":"Wybierz datę realizacji"},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('Icon',{staticClass:"mr-2",attrs:{"name":"date","size":"small"}})]},proxy:true},{key:"append",fn:function(){return [_c('SwitchDayButtons',{attrs:{"min":_vm.minDate},model:{value:(_vm.task.dueDate),callback:function ($$v) {_vm.$set(_vm.task, "dueDate", $$v)},expression:"task.dueDate"}})]},proxy:true}],null,true)},'v-text-field',Object.assign({}, attrs, _vm.attributes.textField),false),on))]}}]),model:{value:(_vm.showDatePicker),callback:function ($$v) {_vm.showDatePicker=$$v},expression:"showDatePicker"}},'v-menu',_vm.attributes.menu,false),[_c(VDatePicker,_vm._b({attrs:{"min":_vm.minDate},on:{"input":function($event){_vm.showDatePicker = false}},model:{value:(_vm.task.dueDate),callback:function ($$v) {_vm.$set(_vm.task, "dueDate", $$v)},expression:"task.dueDate"}},'v-date-picker',_vm.attributes.datePicker,false))],1)],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextarea,{attrs:{"outlined":"","rows":3,"auto-grow":"","label":"Opis zadania","rules":[_vm.rules.required],"placeholder":"Wpisz opis zadania"},model:{value:(_vm.task.description),callback:function ($$v) {_vm.$set(_vm.task, "description", $$v)},expression:"task.description"}})],1)],1)],1)]},proxy:true},{key:"submit",fn:function(){return [_c(VBtn,{staticClass:"base-hover",attrs:{"color":"primary","loading":_vm.isProcessing,"type":"submit","form":"addNewTaskForm"}},[_vm._v(" Dodaj zadanie ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }